import './about.css'
function About() {
  return (
    <div className="about">
      <h2>About us</h2>
      <p>Saurav Tours & Travels ( Legal name: Saurav Enterprises) is an Indian Travel agency with a goal to provide best hospitality and best service to the tourists in India. We are authorised  booking agency  for TSTDC (Telangana Tourism), IRCTC TOURISM, APTDC( Andhra Pradesh Tourism), RAMOJI FILM CITY.</p>

      <p>We tied up with different Private and government organisations who operate Tour and Holiday Packages on daily basis across India.</p>

      <p>Saurav Tours & Travels is committed to provide consistently high quality of services and to continuously improve the services through a process of teamwork for the utmost satisfaction of the passengers and to attain a position of pre-eminence in the bus transport sector. By Serving beyond customer expectations for every journey whilst adding value to all our customers and society at large.</p>

      <h3>Our Mission:</h3>

      <p>Proactively understood customer needs and expectations and fulfil them, by continuously challenging ourselves to provide innovative solutions to the travelling sector.</p>

      <h3>Our Philosophy:</h3>

      <p>Saurav Tours & Travels in building relationship with customers, having different visions and experience, who contribute to managing the growth, direction and success of the company.</p>
    </div>
  );
}

export default About;