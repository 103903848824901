import './header.css'

function Header() {
  return (
    <div className='header'>
        <h3>Saurav Tours & Travels</h3>
    </div>
  );
}

export default Header;