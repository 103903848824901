import { ContactsOutlined, MailOutlined} from '@ant-design/icons';
import './footer.css'
function Footer() {
  return (
    <div className='footer'>
    <ul>
      <li><ContactsOutlined/> Phone: +916300331083</li>
      <li><MailOutlined /> Email : <a href="mailto:sauraventerprises66@gmail.com">sauraventerprises66@gmail.com</a></li>
    </ul>
    <div className='copy-right'>
    Ⓒ 2024 Saurav Tours & Travels Pvt Ltd. All rights reserved
    </div>
    </div>
  );
}

export default Footer;