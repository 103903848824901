
import Header from '../Header/header'
import NavList from '../../components/navBar'
import Footer from '../Footer/footer.'

const Layout = (props) => {
  return (
    <>
      <Header />
      <NavList />
      {props.children}
      <Footer />
    </>
  );
}

export default Layout;