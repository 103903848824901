import React, { useState } from 'react';
import { InfoCircleOutlined, PhoneOutlined, HomeOutlined, DashboardOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import { useNavigate } from 'react-router-dom';
import '../global.css'
const items = [
  {
    label: 'Home',
    key: 'home',
    icon: <HomeOutlined />,
  },
  {
    label: 'About',
    key: 'about',
    icon: <InfoCircleOutlined />,
  },
  {
    label: 'Services',
    key: '/services',
    icon: <DashboardOutlined />,
  },

  {
    label: 'Contact US',
    key: 'contactus',
    icon: <PhoneOutlined />
  },
];
const NavList = () => {
  let navigate = useNavigate();
  const [current, setCurrent] = useState('mail');
  const onClick = (e) => {
    setCurrent(e.key);
    navigate(e.key);
  };
  return <Menu onClick={onClick} selectedKeys={[current]} mode="horizontal" items={items} />;
};
export default NavList;