import { ContactsOutlined, MailOutlined} from '@ant-design/icons';
import './contact.css'
function Contact() {
  return (
    <div className="contact">
      <h2>Contact us</h2>
      <p><ContactsOutlined /> Phone: +91 6300331083</p>
      <p><MailOutlined /> Email: <a href="mailto:sauraventerprises66@gmail.com">sauraventerprises66@gmail.com</a></p>

      
    </div>
  );
}

export default Contact;