
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './index.css';
import Layout from './pages/layout/layout'
import About from './pages/About/about'
import Home from './pages/Home/home'
import Contact from './pages/Contact/contact'
import Services from './pages/Services/services'

ReactDOM.render(
  <React.StrictMode>
       <BrowserRouter>
   <Layout>
   <Routes>
     <Route>
     <Route path="/" element={<Home />} />
       <Route path="/about" element={<About />} />
       <Route path="/home" element={<Home />} />
       <Route path="/services" element={<Services />} />
       <Route path="/contactus" element={<Contact />} />
     </Route>
   </Routes>
   </Layout>
 </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);