import { ArrowRightOutlined } from '@ant-design/icons';
import './home.css'

function Services() {
  return (
    <div className='slider'>
      <div className='container'>
        <p>We tied up with different Private and government organisations who operate Tour and Holiday Packages on daily basis across India.</p>
        <p>Below, you will find various types of buses available to book bus tickets on Saurav Tours & Travels at the lowest fare for bus ticket booking:</p>
        <ul>
          <li><ArrowRightOutlined /> AC Buses</li>
          <li><ArrowRightOutlined /> Non AC Buses</li>
          <li><ArrowRightOutlined /> Ordinary Buses</li>
          <li><ArrowRightOutlined /> Mini Buses</li>
          <li><ArrowRightOutlined /> Super Luxury (Non-AC Seater)</li>
          <li><ArrowRightOutlined /> Volvo AC Buses</li>
          <li><ArrowRightOutlined /> Sleeper AC Buses</li>
          <li><ArrowRightOutlined /> Sleeper Buses</li>
          <li><ArrowRightOutlined /> Deluxe Buses</li>
          <li><ArrowRightOutlined /> Sleeper Cum Seater AC</li>
          <li><ArrowRightOutlined /> Express Buses</li>
        </ul>
        <p>The bus ticket fare depends on the factors such as type of bus, bus operator, bus routes distance between origin and destination cities, amenities offered by the bus operator, and bus operators may change ticket prices during festive seasons. Travelling by bus journey is the best option, cost effective and the most convenient travel option compared to other modes of transport.</p>
      </div>
    </div>
  );
}

export default Services;