import { Carousel } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import image from '../../images/11.jpg'
import image2 from '../../images/2.webp'
import image3 from '../../images/3.jpg'
import './home.css'

function Home() {
  const onChange = (currentSlide) => {
    console.log(currentSlide);
  };
  return (
    <div className='slider'>
      <Carousel afterChange={onChange} arrows effect="fade" autoplay>
        <div>
          <img src={image} alt="" />
        </div>
        <div>
          <img src={image2} alt="" />
        </div>


      </Carousel>
      <div className='container'>
        <p>Saurav Tours & Travels ( Legal name: Saurav Enterprises) is an Indian Travel agency with a goal to provide best hospitality and best service to the tourists in India. We are authorised  booking agency  for TSTDC (Telangana Tourism), IRCTC TOURISM, APTDC( Andhra Pradesh Tourism), RAMOJI FILM CITY.</p>

        <p>We tied up with different Private and government organisations who operate Tour and Holiday Packages on daily basis across India.</p>

        <p>Saurav Tours & Travels is committed to provide consistently high quality of services and to continuously improve the services through a process of teamwork for the utmost satisfaction of the passengers and to attain a position of pre-eminence in the bus transport sector. By Serving beyond customer expectations for every journey whilst adding value to all our customers and society at large.</p>
        <p>Online Bus Ticket Booking at Lowest Price With Saurav Tours & Travels, travellers can book bus tickets online at the lowest ticket fares.</p>
        <p>Travellers can choose their favorite bus for online bus booking. Saurav Tours & Travels is the right place for reserving bus tickets as you will find a wide range of Private buses and SRTC (State Road Transport Corporation) buses are available for bus booking online on Saurav Tours & Travels.</p>
      </div>
    </div>
  );
}

export default Home;